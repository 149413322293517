<template>
  <div class="intergral">
    <div class="top_bar">
      <div>商城</div>
    </div>
    <van-tabs v-model="active" sticky :offset-top="39" @click="onClick">
      <van-tab v-for="item in classifyList" :title="item.name" :key="item.id">
        <div class="container">
          <div class="content" v-for="childItem in shopList" :key="childItem.goods_id" @click="handleDetail(childItem.goods_id)">
            <div class="content_left">
              <img :src="childItem.original_img" alt />
            </div>
            <div class="content_right clearfix">
              <div class="right_title">{{childItem.goods_name}}</div>
              <div class="right_detail">{{childItem.goods_remark}}</div>
              <div class="price">
                <span class="price_text">{{childItem.shop_price}}</span>
                <span>/{{childItem.spu}}</span>
              </div>
              <!-- <div class="buy_now fr" >立即兑换</div> -->
              <div class="buy_now fr" >{{childItem.btn}}</div>
            </div>
          </div>
        </div>
        <div class="none" v-show="(shopList instanceof Array)&&shopList.length==0">暂无商品</div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      nameList: [],
      classifyList: [],
      shopList: []
    };
  },
  methods: {
    handleDetail(id) {
      if (sessionStorage.getItem("mobile_validated") == 0) {
        this.$router.push({
          path: "/login"
        });
      } else if (sessionStorage.getItem("info_validated") == 0) {
        this.$router.push({
          path: "/information"
        });
      } else {
        this.$router.push({
          path: "/detail/index",
          query: {
            id: id
          }
        });
      }
    },
    onClick(name, title) {
      console.log(name, title);
      this.handleGetShopList(this.classifyList[name].id);
    },
    async handleGetClassify() {
      const data = await this.$api.getClassify();
      if (data.data.code === 1) {
        let list = [{ id: 0, name: "全部" }];
        this.classifyList = list.concat(data.data.data);
        this.handleGetShopList(this.classifyList[0].id);
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleGetShopList(id) {
      const data = await this.$api.getShopList({
        goods_category: id
      });
      if (data.data.code === 1) {
        this.shopList = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
        this.shopList = [];
      }
    }
  },
  mounted() {
    this.handleGetClassify();
  }
};
</script>

<style lang="scss" scoped>
.intergral {
  width: 375px;
  height: 100%;
  min-height: 100vh;
  background: rgba(245, 245, 245, 1);
  padding-bottom: 50px;
  box-sizing: border-box;
  .container {
    width: 100%;
    background: #fff;
    .content {
      width: 351px;
      margin: 0 auto;
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      padding: 14px 0;
      box-sizing: border-box;
      .content_left {
        width: 122px;
        height: 122px;
        margin-right: 11px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }
      .content_right {
        width: 230px;
        .right_title {
          width: 200px;
          font-size: 16px;
          height: 16px;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 16px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
        .right_detail {
          width: 200px;
          height: 40px;
          font-size: 12px;
          color: rgba(153, 153, 153, 1);
          line-height: 20px;
          margin-top: 15px;
          overflow: hidden;
        }
        .price {
          margin-top: 15px;
          font-size: 12px;
          color: #666;
          font-weight: 500;
          line-height: 12px;
          .price_text {
            color: #fd2a2a;
          }
        }
        .buy_now {
          width: 70px;
          height: 25px;
          background: #0E3592;
          border-radius: 15px;
          font-size: 12px;
          color: rgba(255, 255, 255, 1);
          line-height: 25px;
          text-align: center;
          background-image: url("~@/assets/image/btn_img.png");
          background-size: 70%;
          background-repeat: no-repeat;
        }
      }
      &:last-child {
        border: none;
      }
    }
  }
  .none {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #999;
    margin-top: 100px;
  }
}
</style>

 